<template>
  <b-card>
    <b-row v-if="isPrint">
      <b-col
        cols="12"
        style="align-self: center; text-align: end;"
      >
        <b-button
          class="mr-1"
          size="sm"
          variant="primary"
          @click="print"
        >
          <span v-if="loading">
            <b-spinner small />
            Loading...
          </span>
          <span v-else>Print</span>
        </b-button>
      </b-col>
    </b-row>
    <info @showData="showData" />
  </b-card>
</template>

<script>

import {BRow, BCol, BCard,BTabs, BTab, BButton, BSpinner} from 'bootstrap-vue'

import Info from './include/Info.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    Info,
    BButton,
    BSpinner
  },
  data(){
    return {
      isPrint:false,
      loading:false
    }
  },
  methods:{
    showData(result){
      if(result.status == 'approved'){
        this.isPrint = true
      }
    },
    print(){
      this.loading = true
      this.$http.get('claim/'+this.$route.params.id+'/print').then(res=>{
        this.loading = false
        window.open(res.data.filename)
      })
    }
  }
}
</script>
