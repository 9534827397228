<template>
  <b-card>
    <base-info
      ref="baseinfo"
      :geturl="dataurl"
      :fields="fields"
      :baseroute="baseroute"
      :col_single="false"
      :isedit="model.status == 'created'"
      :isdelete="model.status == 'created'"
      :is-action="model.status == 'created'"
      @readData="showData"
    >

      <template slot="buttonaction">
        <b-button
          class="mr-1"
          size="sm"
          variant="info"
          type="button"
          @click.prevent="action('approve')"
        >
          <span>Setujui</span>
        </b-button>
        <b-button
          class="mr-1"
          size="sm"
          variant="warning"
          type="button"
          @click.prevent="action('reject')"
        >
          <span>Batalkan</span>
        </b-button>
      </template>
    </base-info>

    <b-row>
      <b-col cols="12">
        <hr>
      </b-col>
      <b-col cols="12">
        <b-tabs>
          <b-tab active>
            <template #title>
              <feather-icon icon="TruckIcon" />
              Detail Perawatan
            </template>
            <!-- <h3 class="font-weight-bolder">
              Detail Perawatan
            </h3> -->
            <br>
            
            <b-overlay
              :show="loading"
              variant="light"
              :opacity="0.5"
              rounded="sm"
            >

              <b-table
                striped
                responsive
                :items="items"
                :fields="fieldsTable"
              />

            </b-overlay>
          </b-tab>
          <b-tab>
            <template #title>
              <feather-icon icon="FileIcon" />
              Daftar File
            </template>
            <!-- <h3 class="font-weight-bolder">
              Detail Perawatan
            </h3> -->
            <br>
            
            <b-overlay
              :show="loading"
              variant="light"
              :opacity="0.5"
              rounded="sm"
            >

              <b-table
                striped
                responsive
                :items="itemFile"
                :fields="fieldsTableFile"
              />

            </b-overlay>
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>

import {BCard, BCol, BRow, BTable, BOverlay, BButton, BTabs, BTab} from 'bootstrap-vue'
import BaseInfo from '@/views/base/BaseInfo.vue'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    VueApexCharts,
    BCard,
    BRow,
    BCol,
    BaseInfo,
    BTable, 
    BOverlay,
    BButton,
    BTabs,
    BTab
  },

  data() {
    return {
      dataurl:"/claim",
      baseroute:"vehicle-claim",
      fields: [
        { key: 'number', label: 'Nomor Klaim', bold:true},
        { key: 'date', label: 'Tgl Pengajuan' , type:'date'},
        { key: 'spb.number', label: 'Nomor SPB', bold:true},
        { key: 'vehicle.number', label: 'Nopol Kendaraan'},
        // { key: 'maintenance.code', label: 'Code Perawatan'},
        { key: 'author.name', label: 'Pemberi Persetujuan' },
        { key: 'status', label: 'Status', type:'spanKey', data:{created:{color:'bg-warning',value:'Pengajuan'}, approved:{color:'bg-primary',value:'Disetujui'}, rejected:{color:'bg-danger',value:'Dibatalkan'}} },
        // { key: 'note', label: 'Keterangan' },
        // { key: 'author.name', label: 'Pemesanan' },
        // { key: 'received_date', label: 'Tgl Persetujuan' , type:'date'},
        // { key: 'destination.name', label: 'Cabang Tujuan' },
      ],
      fieldsTable: [
        { key: 'code', label: 'Kode'},
        { key: 'name', label: 'Nama Perawatan'},
        { key: 'plan_cost', label: 'Biaya' },
        { key: 'description', label: 'Keterangan' },
        // { key: 'status', label: 'Status' },
      ],
      fieldsTableFile: [
        { key: 'file', label: 'Nama File'},
      ],
      items:[],
      itemFile:[],
      loading:false,
      model:[]
    }
  },
  methods: {
    showData(result){
      this.$emit('showData', result)
      this.model = result
      this.items = result.maintenances
      this.itemFile = result.files
    },
    action(action){
      const id = this.$route.params.id
      
      this.$bvModal
        .msgBoxConfirm('Are you sure ?', {
          title: 'Please Confirm',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
          okVariant:'info',
          okTitle: 'Yes',
          cancelTitle: 'No',
        })
        .then(confirm => {
          if(confirm){
            this.$http.put(this.dataurl+'/'+id+'/'+action)
              .then(() => {
                this.$refs.baseinfo.fetchData()
            })
          }
        })
    }
  }
}
</script>
